.faqSection {
  background-color: black;
  color: white;
  font-family: 'Inter', sans-serif;
  padding: 2rem;
  margin-top: 4rem;
}

.title {
  color: #0281ff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.faqCard {
  background-color: #222;
  border-left: none;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 1rem;
}

.faqToggle {
  display: flex;
  align-items: center;
  font-size: 17px; /* 1px bigger */
  font-weight: bold; /* Make it bold */
  color: white; 
}

.faqIcon {
  margin-right: 1rem;
}

.faqAnswer {
  display: none;
  background-color: rgba(23, 30, 37, 0.48);
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.5;
}

.faqCard.open .faqAnswer {
  display: block;
}


.faqAnswerStyle {
  font-family: 'Inter', sans-serif; /* Using the Inter font */
  color: white; /* Blue color from your index.css */
  font-size: 15px; /* You can adjust the size */
  line-height: 1.5; /* Adjust line height */
  margin-top: 10px; /* Add some margin at the top */
}
